//
//
//
//
//
//
//
//
//
//

import CardTitle from './CardTitle';

export default {
  name: 'Card',
  components: { CardTitle },
  props: {
    icon: {
      type: Array,
      required: false,
      default: () => ['fab', 'accessible-icon'],
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
