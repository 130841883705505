//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ServiceImage from './ServiceImage';
export default {
  name: 'Service',
  components: { ServiceImage },
  props: {
    square: {
      type: Boolean,
      default: false,
    },
    triangle: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
