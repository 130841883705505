//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AppSection } from './index';

export default {
  name: 'Audience',
  components: { AppSection },
};
