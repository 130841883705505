//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AppSection } from '@/components/sections';
import AppButton from '../AppButton.vue';

export default {
  name: 'Breaker',
  components: { AppSection, AppButton },
  data() {
    return {
      x: 0,
      y: 0,
    };
  },
  computed: {
    rocketPosition() {
      if (process.client) {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        let x = (this.x - screenWidth / 2) / 25;
        let y = (this.y - screenHeight / 2) / 10;

        if (x > 200) x = 200;
        if (y > 200) y = 200;
        return `transform: translate(${x}px, ${y}px)`;
      }
      return null;
    },
  },
  mounted() {
    window.addEventListener('mousemove', (e) => {
      this.x = e.clientX;
      this.y = e.clientY;
    });
  },
};
