//
//
//
//
//
//
//
//
//
//

import MagicButton from '@/components/MagicButton';

export default {
  name: 'FloatingButton',
  components: {
    MagicButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
