//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppButton from '@/components/AppButton';
import FloatingButton from '@/components/FloatingButton';
import Envelope from '@/assets/svg/envelope.svg';

export default {
  name: 'Hero',
  components: { AppButton, FloatingButton, Envelope },
  props: {
    isShowingFloatingButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollY: 0,
    };
  },
  computed: {
    isFloating() {
      let offset = 0;
      if (process.client) {
        offset = document.querySelector('.hero__cta')?.offsetTop;
      }

      return offset <= this.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.scrollY = window.top.scrollY;
    },
  },
};
