//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as colors from '@/assets/scss/modules/_colors.module.scss';

export default {
  name: 'MagicButton',
  props: {
    fab: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deg: 0,
      animator: null,
    };
  },
  computed: {
    buttonBackground() {
      if (this.disabled) return;
      return `background-image: linear-gradient(${this.deg / 2.5}deg,${
        colors.gradient2Start
      }, ${colors.gradient2End});`;
    },
  },
  mounted() {
    this.animator = setInterval(() => {
      this.deg++;
    }, 7.5);
  },
  beforeDestroy() {
    clearInterval(this.animator);
  },
};
