//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppButton',
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      return [
        this.shadow ? 'button--shadow' : null,
        this.alt ? 'button--alt' : null,
        this.loading ? 'button--loading' : null,
        this.secondary ? 'button--secondary' : null,
      ];
    },
  },
};
