//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MagicButton from '@/components/MagicButton';

export default {
  name: 'AppFooter',
  components: { MagicButton },
  data() {
    return {
      scrollY: 0,
    };
  },
  computed: {
    isVisible() {
      let offset = 0;
      let screenHeight = 0;
      if (process.client) {
        offset = document.querySelector('.footer')?.offsetTop;
        screenHeight = window.innerHeight;
      }

      return offset - screenHeight - 100 <= this.scrollY;
    },
  },
  watch: {
    isVisible(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('visible', val);
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.scrollY = window.top.scrollY;
    },
  },
};
