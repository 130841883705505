//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Service from '@/components/service/Service';
import { AppSection, SectionTitle } from './index';

export default {
  name: 'Services',
  components: { AppSection, SectionTitle, Service },
  data() {
    return {
      services: [
        {
          circle: true,
          title: this.$i18n.t('services[0].title'),
          description: this.$i18n.t('services[0].description'),
          alt: this.$i18n.t('services[0].alt'),
          image: 'wacom',
        },
        {
          square: true,
          title: this.$i18n.t('services[1].title'),
          description: this.$i18n.t('services[1].description'),
          alt: this.$i18n.t('services[1].alt'),
          image: 'laptop',
        },
        {
          triangle: true,
          title: this.$i18n.t('services[2].title'),
          description: this.$i18n.t('services[2].description'),
          alt: this.$i18n.t('services[2].alt'),
          image: 'server',
        },
      ],
    };
  },
};
