//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Card from '@/components/card/Card';
import { AppSection, SectionTitle } from './index';

export default {
  name: 'Expertise',
  components: { AppSection, SectionTitle, Card },
  data() {
    return {
      cards: [
        {
          icon: ['fab', 'accessible-icon'],
          title: this.$i18n.t('expertises[0].title'),
          description: this.$i18n.t('expertises[0].description'),
        },
        {
          icon: ['fas', 'chart-line'],
          title: this.$i18n.t('expertises[1].title'),
          description: this.$i18n.t('expertises[1].description'),
        },
        {
          icon: ['fas', 'mobile-alt'],
          title: this.$i18n.t('expertises[2].title'),
          description: this.$i18n.t('expertises[2].description'),
        },
        {
          icon: ['fas', 'search-dollar'],
          title: this.$i18n.t('expertises[3].title'),
          description: this.$i18n.t('expertises[3].description'),
        },
        {
          icon: ['fas', 'rocket'],
          title: this.$i18n.t('expertises[4].title'),
          description: this.$i18n.t('expertises[4].description'),
        },
        {
          icon: ['far', 'check-circle'],
          title: this.$i18n.t('expertises[5].title'),
          description: this.$i18n.t('expertises[5].description'),
        },
      ],
    };
  },
};
