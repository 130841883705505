//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CardTitle',
  props: {
    tag: {
      type: String,
      default: 'h3',
    },
  },
};
