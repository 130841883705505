//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppSection',
  props: {
    name: {
      type: String,
      required: true,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: 'white',
    },
  },
};
